const Light = `
        .
      \ | /
    '-.;;;.-'
   -==;;;;;==-
    .-';;;'-.
      / | \
`

const Dark = `
         _.._
        .' .-'\`
       /  /
       |  |
       \  '.___.;
        '._  _.'
           \`\`
`

export default {
  Light,
  Dark
}