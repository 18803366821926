const theme = {
  light: {
    background: "white",
    textPrimary: "black",
    textShadow: "0 0 10px rgba(100, 100, 100, 1)",
  },
  dark: {
    background: "black",
    textPrimary: "#00bbff",
    textShadow: "0 0 10px rgba(0, 85, 255, 1)",
  },
};

// csshelper
class CSSHelper {
  setTheme(lightMode, root, logo, mail) {
    root.style.backgroundColor = theme[lightMode ? "light" : "dark"].background;
    root.style.color = theme[lightMode ? "light" : "dark"].textPrimary;
    logo.style.textShadow = theme[lightMode ? "light" : "dark"].textShadow;
    mail.style.color = theme[lightMode ? "light" : "dark"].textPrimary;
  }
  withAscii(ele) {
    ele.classList.add("language-ascii-art");
    return this;
  }
  withScale(ele) {
    ele.classList.add("scale-font");
    return this;
  }
  withMonospace(ele) {
    ele.style.fontFamily = "Lucida Console, Monaco, monospace";
    return this;
  }
  withFloat(ele, pos = "right") {
    ele.style.float = pos;
    ele.style.marginRight = "10px"
    return this;
  }
  withHover(ele, type = "pointer") {
    ele.style.cursor = type;
    return this;
  }
  withNoHighlight(ele) {
    ele.style.userSelect = "none";
    return this;
  }
  withMargin(ele, margin = 0) {
    ele.style.margin = `${margin}px`;
    return this;
  }
  fadeIn(ele) {
    ele.classList.add('show');
    ele.classList.remove("hide")
    return this;
  }
}

export default new CSSHelper();
