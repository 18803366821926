import "./index.css";
import PickLogo, { logos } from "./PickLogo";
import ThemeIcons from "./ThemeIcons";
import CSSHelper from "./CSSHelper";

let storedTheme = JSON.parse(localStorage.getItem("theme"));
let lightMode = storedTheme || false;

let storedLogo = JSON.parse(localStorage.getItem("logo")) || 2;
let startLogo = logos[storedLogo];

// header
const root = document.getElementById("root");
const header = document.createElement("header");
const logo = document.createElement("div");
logo.id = "logo";
logo.innerText = startLogo;
CSSHelper.withAscii(logo).withScale(logo).withNoHighlight(logo);
header.appendChild(logo);

const themeButton = document.createElement("div");
themeButton.innerText = lightMode ? ThemeIcons.Light : ThemeIcons.Dark;
CSSHelper.withAscii(themeButton)
  .withScale(themeButton)
  .withFloat(themeButton)
  .withHover(themeButton)
  .withNoHighlight(themeButton);

header.appendChild(themeButton);
root.appendChild(header);

// tearlines
let tearlineDashes = "";
let maxWidth = window.outerWidth / 5;
for (let i = 0; i < maxWidth; i++) {
  tearlineDashes += "=";
}
const tearline = document.createElement("div");
tearline.innerText = tearlineDashes;
CSSHelper.withAscii(tearline).withScale(tearline).withMargin(tearline, 0);
root.appendChild(tearline);

const descriptions = [
  "FerSher LLC is a software consultancy based out of North Carolina",
  "We specialize with customers who have unique problem sets",
  "Our Skills",
];

descriptions.forEach((d) => {
  let e = document.createElement("h1");
  e.innerText = d;
  e.style.fontFamily = '"Lucida Console", Monaco, monospace';
  CSSHelper.withScale(e).withMargin(e, 15);
  root.appendChild(e);
});

const list = [
  "Cloud",
  "Linux",
  "Microservices",
  "Docker",
  "Integrations",
  "Custom Workflows",
  "Performance Critical Applications",
];

const ul = document.createElement("ul");
list.forEach((d) => {
  let item = document.createElement("li");
  item.innerText = d;
  item.style.fontFamily = '"Lucida Console", Monaco, monospace';
  CSSHelper.withScale(item).withMargin(item, 15);
  ul.appendChild(item);
});
root.appendChild(ul);

const footer = document.createElement("footer");
const mail = document.createElement("a");
mail.href = "mailto:jake@fersher.sh";
mail.innerText = "mail us: jake@fersher.sh";
mail.style.color = "black";
mail.style.fontFamily = '"Lucida Console", Monaco, monospace';
footer.style.textAlign = "center";
footer.style.position = "absolute";
footer.style.width = "100%";
footer.style.bottom = "0";
footer.style.paddingBottom = "10px";
footer.appendChild(mail);
root.appendChild(footer);

themeButton.addEventListener("click", () => {
  lightMode = !lightMode;
  themeButton.innerText = [lightMode ? ThemeIcons.Light : ThemeIcons.Dark];
  CSSHelper.setTheme(lightMode, document.body, logo, mail);
  localStorage.setItem("theme", lightMode.toString());
});

logo.addEventListener("click", () => {
  let [logoText, logoIdx] = PickLogo();
  logo.innerText = logoText;
  localStorage.setItem("logo", logoIdx + "");
});
CSSHelper.setTheme(lightMode, document.body, logo, mail);
CSSHelper.fadeIn(root);
