// texts
const logo1 = `

███████ ███████ ██████  ███████ ██   ██ ███████ ██████
██      ██      ██   ██ ██      ██   ██ ██      ██   ██
█████   █████   ██████  ███████ ███████ █████   ██████
██      ██      ██   ██      ██ ██   ██ ██      ██   ██
██      ███████ ██   ██ ███████ ██   ██ ███████ ██   ██

`;
const logo2 = `

8888 8888 888b. .d88b. 8   8 8888 888b. 
8www 8www 8  .8 YPwww. 8www8 8www 8  .8 
8    8    8wwK'     d8 8   8 8    8wwK' 
8    8888 8  Yb 'Y88P' 8   8 8888 8  Yb

 
`;
const logo3 = `

.######.######.#####...####..##..##.######.#####..
.##.....##.....##..##.##.....##..##.##.....##..##.
.####...####...#####...####..######.####...#####..
.##.....##.....##..##.....##.##..##.##.....##..##.
.##.....######.##..##..####..##..##.######.##..##.

`;

const logo4 = `

 ____  ____  ____  ____  _  _  ____  ____ 
(  __)(  __)(  _ \\/ ___)/ )( \\(  __)(  _ \\
 ) _)  ) _)  )   /\\___ \\) __ ( ) _)  )   /
(__)  (____)(__\\_)(____/\\_)(_/(____)(__\\_)

`;

const logo5 = `

     dBBBBP dBBBP dBBBBBb .dBBBBP   dBP dBP dBBBP dBBBBBb
     dBP   BP                       dB  P
   dBBBP  dBBP    dBBBBK  \`BBBBb  dBBBBBP dBBP    dBBBBK 
  dBP    dBP     dBP  BB     dBP dBP dBP dBP     dBP  BB 
 dBP    dBBBBP  dBP  dB'dBBBBP' dBP dBP dBBBBP  dBP  dB'
 `

const logo6 = `
,d8888b                         d8b                      
88P'                            ?88                      
d888888P                            88b                     
?88'     d8888b  88bd88b .d888b,  888888b  d8888b  88bd88b
88P     d8b_,dP  88P'  \` ?8b,     88P \`?8bd8b_,dP  88P'  \`
d88      88b     d88        \`?8b  d88   88P88b     d88     
d88'      \`?888P'd88'     \`?888P' d88'   88b\`?888P'd88'`

const logo7 = `


  ^    ^    ^    ^    ^    ^    ^  
 /F\\  /e\\  /r\\  /S\\  /h\\  /e\\  /r\\ 
<___><___><___><___><___><___><___>

`

const logo8 = `
   ___         __ _               
  / __\\__ _ __/ _\\ |__   ___ _ __ 
 / _\\/ _ \\ '__\\ \\| '_ \\ / _ \\ '__|
/ / |  __/ |  _\\ \\ | | |  __/ |   
\\/   \\___|_|  \\__/_| |_|\\___|_|   

 `

 const logo9 = `
   ___                    ___     _                      
  | __|   ___      _ _   / __|   | |_      ___      _ _  
  | _|   / -_)    | '_|  \\__ \\   | ' \\    / -_)    | '_| 
 _|_|_   \\___|   _|_|_   |___/   |_||_|   \\___|   _|_|_  
_| """ |_|"""""|_|"""""|_|"""""|_|"""""|_|"""""|_|"""""| 
"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-'"\`-0-0-' 
 `

export const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9];

export default () => {
  let idx = Math.floor(Math.random() * logos.length)
  return [logos[idx], idx];
};